
export default {
  data() {
    return {
      /**
       * Request jarallax to launch parallax for this page
       */
      parallax: false,
      title: "Mécanika'Swing",
      today: new Date(),
    }
  },
  head() {
    return {
      link: [
        { rel: 'icon', type: 'image/png', href: '/ms-atelier-favicon.png' },
      ],
    }
  },
}
