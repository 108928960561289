
import 'lazysizes'
import 'lazysizes/plugins/blur-up/ls.blur-up'
import { mapState } from 'vuex'
import util from '~/assets/js/util.mjs'

export default {
  data() {
    return {
      title: "Mécanika'Swing",
      clipped: false,
      drawer: null,
      fixed: false,
      activeNav: null,
      contactEmail: util.getApsEmail(),
      contactPhone: util.getApsPhone(),
      items: [
        {
          title: 'Hello',
          tooltip: 'Bienvenue sur notre site !',
          to: '#intro',
        },
        {
          title: 'Évènements',
          tooltip: 'Ca se passe bientôt !',
          to: '#evenements',
        },
        {
          title: 'Cours réguliers',
          tooltip: 'Toutes les informations sur les cours réguliers',
          to: '#classes',
        },
        {
          title: 'Planning',
          tooltip: 'Planning des cours réguliers',
          to: '#planning',
          sub: true,
        },
        {
          title: 'Description des cours',
          tooltip: 'Description des cours',
          to: '#description-cours',
          sub: true,
        },
        {
          title: 'Tarifs',
          tooltip: 'Tarifs des cours réguliers',
          to: '#price',
          sub: true,
        },
        {
          title: 'Qui sommes-nous ?',
          tooltip: "Nous vous présentons l'équipe !",
          to: '#teachers',
        },
        // {
        //   title: 'Retrospective 2023-24',
        //   tooltip: 'Tous ce que nous avons fait la saison dernière !',
        //   to: '#saison-2023-24',
        // },
        {
          title: 'Nous contacter',
          tooltip: 'Vous voulez nous contacter ?',
          to: '#contact',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      currentSection: (state) => state.navigation.section,
    }),
  },
  watch: {
    currentSection(newCurrent) {
      const hash = '#' + encodeURIComponent(newCurrent)

      const items = this.items
      for (const key in items) {
        const item = items[key]
        item.active = item.to === hash

        if (item.active) {
          this.activeNav = key
        }
      }

      // console.log('watch currentSection', items, hash)

      history.pushState({}, null, this.$route.path + hash)
      // this.$router.replace(hash)
    },
  },
  // mounted() {
  //   this.$nextTick(function() {
  //     // Code that will run only after the
  //     // entire view has been rendered
  //
  //     // eslint-disable-next-line no-console
  //     console.log('Hooray, Nuxt.js app mounted.')
  //
  //     // object-fit polyfill run
  //     objectFitImages()
  //     /* init Jarallax */
  //     jarallax(document.querySelectorAll('.jarallax'))
  //     jarallax(document.querySelectorAll('.jarallax-keep-img'), {
  //       keepImg: true
  //     })
  //
  //     jarallaxElement()
  //     jarallaxVideo()
  //   })
  // }
}
