import Config from '~/assets/js/config.mjs'

const COLORS = {
  'main-red': '#ee1d4d',
  'main-yellow': '#faea83',
  'main-orange': '#fbc156',
  'main-blue': '#afddd2',
  'main-rose': '#fae9dc',
  'main-purple': '#59355e',
  mecanikaswing: '#442300',
  'mecanikaswing-alt': '#cc9933',
  'mecanikaswing-text': '#442300',
  'mecanikaswing-light': '#eed3a3',
  'mecanikaswing-yellow': '#ffd479',
  'mecanikaswing-metal': '#979798',
  'mecanikaswing-metal-light': '#ccc',
  'mecanikaswing-metal-dark': '#4f4b53',
  // 'mecanikaswing-v2': '#442300',
  // 'mecanikaswing-v2-alt': '#cc9933',
}

const util = {
  DANSES: {
    LINDYHOP: 'Lindy Hop',
    BALBOA: 'Balboa',
    CHARLESTON: 'Charleston Solo',
    ACROBATIE: 'Acrobaties',
  },
  /**
   *
   * @param {*} protect
   * @returns string
   */
  getApsEmail: (protect = true) =>
    protect ? Config.APS_EMAIL_PROTECTED : Config.APS_EMAIL,
  /**
   *
   * @returns string
   */
  getApsPhone: () => Config.APS_PHONE,
  /**
   *
   * @param {string} filename
   * @returns string
   */
  getExtension: (filename) => filename.split('.').pop(),
  /**
   *
   * @param {string} filename
   * @returns string
   */
  getFileNameOnly: (filename) =>
    filename.replace(/\.[^/.]+$/, '').replace(/^.*[\\/]/, ''),
  /**
   *
   * @param {*} date
   * @param {Object} configDate
   * @param {Object} configTime
   * @param {string} locale 'default'
   * @returns
   */
  formatDate(
    date = null,
    configDate = {},
    configTime = {},
    locale = 'default'
  ) {
    const dateTransform = typeof date === 'string' ? new Date(date) : date
    const dateValue = this.isValidDate(dateTransform)
      ? dateTransform
      : new Date()

    return (
      dateValue.toLocaleDateString(locale, configDate) +
      (configTime !== false
        ? ' ' + dateValue.toLocaleTimeString(locale, configTime)
        : '')
    )
  },
  /**
   *
   * @param {Date} first
   * @param {Date} second
   */
  datesAreOnSameDay(first, second = new Date()) {
    return (
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate()
    )
  },
  /**
   *
   * @param {*} number
   */
  formatPhoneNumber(number) {
    var nonInt = /\D/g
    var allNumbers = /.*(\d{1})(\d{2})(\d{2})(\d{2})/
    var formatStyle = '0$1 $2 $3 $4'
    return number.replace(nonInt, '').replace(allNumbers, formatStyle)
  },
  /**
   *
   * @param {string} string
   * @returns
   */
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  },
  /**
   * Return the some of the values
   *
   *
   * @param {Object} obj
   * @returns
   */
  objectValuesSum(obj) {
    var sum = 0
    for (var el in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, el)) {
        sum += obj[el] //parseFloat(obj[el])
      }
    }
    return sum
  },
  /**
   *
   * @param {Object} obj
   * @returns boolean
   */
  isObjectEmpty: (obj) => {
    return (
      obj &&
      Object.keys(obj).length === 0 &&
      Object.getPrototypeOf(obj) === Object.prototype
    )
  },
  /**
   *
   * @param {string} img
   * @returns string
   */
  getLqip: (img, noPath = false) => {
    let filename = util.getFileNameOnly(img)

    return noPath !== true ? 'lqip/' : '' + `${filename}.jpg`
  },
  /**
   *
   * @param {string} title
   * @param {string} description
   * @returns Object
   */
  getMeta: (title, description) => {
    return [
      // General
      {
        hid: 'description',
        property: 'description',
        content: description,
      },
      // Open Graph
      {
        hid: 'itemprop:description',
        itemprop: 'description',
        content: description,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description,
      },
      {
        hid: 'twitter:description',
        property: 'twitter:description',
        content: description,
      },
    ]
  },
  /**
   *
   * @param {Date} d
   * @returns boolean
   */
  isValidDate: (d) => {
    return d instanceof Date && !isNaN(d)
  },
  /**
   *
   * @param {Date} date1
   * @param {Date} (date2) default today
   * @returns {int} number of days between date1 and date2
   */
  dateDaysDiff: (date1, date2 = new Date()) => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000

    const difference = date1.getTime() - date2.getTime()
    const days = Math.ceil(difference / millisecondsPerDay)

    return days
  },
  registrationOpen(openDate, closeDate) {
    return new Date() > openDate && !this.registrationClose(openDate, closeDate)
  },
  registrationClose(openDate, closeDate) {
    return new Date() > closeDate
  },
  /**
   *
   * @param {*} code
   * @returns
   */
  getColor: (code) => {
    let color = null
    if (Object.prototype.hasOwnProperty.call(COLORS, code)) {
      color = COLORS[code]
    }
    return color
  },
  getColorClass(code) {
    const danses = this.DANSES
    let colorClass = ''

    switch (code) {
      case danses.LINDYHOP:
        colorClass = 'lindyhop'
        break
      case danses.BALBOA:
        colorClass = 'balboa'
        break
      case danses.CHARLESTON:
        colorClass = 'charleston'
        break
      case danses.ACROBATIE:
        colorClass = 'acrobatie'
        break
    }
    return colorClass
  },

  // ------ Gestion des saisons

  /**
   * getSeasonData
   *
   *
   *
   * @param {Date} date
   * @returns
   */
  getSeasonData: (date = new Date()) => {
    // console.log('getSeasonData', 'params: date', date)

    if (typeof date === 'number') {
      const increment = date
      date = new Date()
      let yearTmp = date.getFullYear()
      let monthTmp = date.getMonth()
      let dayTmp = date.getDate()
      date = new Date(yearTmp + increment, monthTmp, dayTmp)

      // console.log('getSeasonData', 'typeof date === number', date)
    }

    const year = date.getFullYear()

    let firstYear
    let secondYear

    // 1st septembre
    const limit = new Date(year + '-09-01')

    // console.log(
    //   'getSeasonData',
    //   'year',
    //   year,
    //   'limit',
    //   limit,
    //   'date < limit',
    //   date < limit
    // )

    if (date > limit) {
      firstYear = limit.getFullYear()
      secondYear = firstYear + 1
    } else {
      secondYear = limit.getFullYear()
      firstYear = secondYear - 1
    }

    // console.log(
    //   'getSeasonData',
    //   'firstYear',
    //   firstYear,
    //   'secondYear',
    //   secondYear
    // )

    return {
      first: firstYear,
      last: secondYear,
      start: new Date(firstYear + '-09-01'),
      end: new Date(secondYear + '-09-01'),
      next: new Date(secondYear + '-06-01'),
      short:
        'Saison' +
        ' ' +
        firstYear.toString() +
        '-' +
        secondYear.toString().substring(2),
      long:
        'Saison' + ' ' + firstYear.toString() + ' - ' + secondYear.toString(),
    }
  },
}

export default util
