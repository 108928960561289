
export default {
  data() {
    return {
      isHydrated: false,
      title: "Mécanika'Swing",
    }
  },
  computed: {
    // init
    breakpoint() {
      return this.isHydrated
        ? this.$vuetify.breakpoint
        : { lgAndUp: '', mdAndDown: '' } // Any constant default value would do here
    },
    isMobileOrTablet() {
      return this.breakpoint.mdAndDown
    },
    isDesktop() {
      return this.breakpoint.lgAndUp
    },
  },
  mounted() {
    this.isHydrated = true
  },
}
