export const state = () => ({
  event: {},
  menu: [],
})

export const getters = {
  getEvent(state) {
    return state.event
  },
  getEventMenu(state) {
    return state.menu
  },
}

export const mutations = {
  /**
   * set nav page
   */
  setEvent(state, event) {
    state.event = event
  },
  /**
   * set nav section
   */
  setEventMenu(state, menu) {
    state.menu = menu
  },
}

// export const getters = {}
