
export default {
  data() {
    return {
      /**
       * Request jarallax to launch parallax for this page
       */
      parallax: true,
      title: 'Aude & Pom',
    }
  },
}
