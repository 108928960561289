
import 'lazysizes'
import 'lazysizes/plugins/blur-up/ls.blur-up'
import { mapState } from 'vuex'
import util from '~/assets/js/util.mjs'

export default {
  data() {
    return {
      title: "Mécanika'Swing",
      clipped: false,
      drawer: null,
      fixed: false,
      activeNav: null,
      contactEmail: util.getApsEmail(),
      contactPhone: util.getApsPhone(),
    }
  },
  computed: {
    ...mapState({
      currentSection: (state) => state.navigation.section,
      event: (state) => state.evenement.event,
      items: (state) => state.evenement.menu,
    }),
  },
  watch: {
    currentSection(newCurrent) {
      const hash = '#' + encodeURIComponent(newCurrent)

      const items = this.items
      for (const key in items) {
        const item = items[key]
        item.active = item.to === hash

        if (item.active) {
          this.activeNav = key
        }
      }

      history.pushState({}, null, this.$route.path + hash)
    },
  },
}
