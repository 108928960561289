import objectFitImages from 'object-fit-images'
import { jarallax, jarallaxElement, jarallaxVideo } from 'jarallax'

import Vue from 'vue'

// import extend from './utils/extend-vue-app'
// import extend from '~/assets/js/extend-vue-app'

Vue.mixin({
  mounted() {
    // eslint-disable-next-line no-console
    // console.debug(
    //   'mixin: Jarallax',
    //   this.isPage(),
    //   this.getComponentName(),
    //   this,
    //   'parallax',
    //   this.parallax,
    //   this.options
    // )

    // if (this.isPage()) {
    // // eslint-disable-next-line no-console
    // console.log('mixin: Jarallax', this.getComponentName())

    this.$nextTick(function() {
      // Need to add data parallax in
      // your page
      if (this.parallax) {
        // Code that will run only after the
        // entire view has been rendered

        // // eslint-disable-next-line no-console
        // console.log('mixin: Jarallax : $nextTick')

        // object-fit polyfill run
        objectFitImages()
        /* init Jarallax */
        jarallaxElement()
        jarallaxVideo()

        jarallax(document.querySelectorAll('.jarallax'))
        jarallax(document.querySelectorAll('.jarallax-keep-img'), {
          keepImg: true
        })
      }
    })
    // }
  },
  methods: {
    isPage() {
      // // eslint-disable-next-line no-console
      // console.log('isPage', this.getComponentName())
      return this.getComponentName().startsWith('pages')
    },
    getComponentName() {
      const vm = this
      if (vm.$root === vm) {
        return 'root instance'
      }
      const name = vm._isVue
        ? vm.$options.name || vm.$options._componentTag
        : vm.name
      return name || 'anonymous' // need to send a string
      // return (
      //   (name ? 'component <' + name + '>' : 'anonymous component') +
      //   (vm._isVue && vm.$options.__file ? ' at ' + vm.$options.__file : '')
      // )
    }
  }
})

// export default function({ app }) {
//   const mixin = {
//     mounted() {
//       // eslint-disable-next-line no-console
//       console.log('Hooray, Nuxt.js app mounted.')
//
//       this.$nextTick(function() {
//         // Code that will run only after the
//         // entire view has been rendered
//
//         // eslint-disable-next-line no-console
//         console.log('Nuxt : $nextTick')
//
//         // object-fit polyfill run
//         objectFitImages()
//         /* init Jarallax */
//         jarallax(document.querySelectorAll('.jarallax'))
//         jarallax(document.querySelectorAll('.jarallax-keep-img'), {
//           keepImg: true
//         })
//
//         jarallaxElement()
//         jarallaxVideo()
//       })
//     }
//   }
//
//   if (!app.mixins) {
//     app.mixins = []
//   }
//   app.mixins.push(mixin)
//
//   app.updateJarallax
// }
