
export default {
  data({ $config }) {
    return {
      title: "Mécanika'Swing",
      version: $config.clientVersion,
      dev: $config.dev,
    }
  },
}
