export const state = () => ({
  list: [],
  loaded: false,
  loading: false,
})

export const mutations = {
  /**
   * set loading state
   */
  setLoading(state, load) {
    state.loading = load
  },
  /**
   * set the list
   */
  set(state, data) {
    state.list = data
    state.loaded = true
    state.loading = false
  },
}

export const getters = {
  eventsByYear: (state) => {
    const list = state.list
    const byYears = {}

    list.forEach((event) => {
      if (!Object.prototype.hasOwnProperty.call(byYears, event.year)) {
        byYears[event.year] = []
      }
      byYears[event.year].push(event)
    })

    return byYears
  },
  years: (state) => {
    // we want only dinstinct years
    return [...new Set(state.list.map((event) => event.year))]
  },
  nexts(state) {
    // // eslint-disable-next-line no-console
    // console.debug('nexts', state)

    return state.list.filter((event) => {
      // event.endDate.isAfter(new Date())
      const endDate = new Date(event.endDate)
      return endDate >= new Date()
    })
  },
  nextYears: (state, getters) => {
    // we want only dinstinct years
    return [...new Set(getters.nexts.map((event) => event.year))]
  },
  nextByYear: (state, getters) => {
    const list = getters.nexts
    const byYears = {}

    list.forEach((event) => {
      if (!Object.prototype.hasOwnProperty.call(byYears, event.year)) {
        byYears[event.year] = []
      }
      byYears[event.year].push(event)
    })

    return byYears
  },
  // mecanika: (state, getters) => {
  //   // we want only dinstinct years
  //   return [...new Set(getters.nexts.map((event) => event.year))]
  // },
}

export const actions = {
  async get({ commit }) {
    // { store } -> ne fonctionne pas
    // // eslint-disable-next-line no-console

    if (!this.state.workshops.loaded) {
      commit('setLoading', true)

      // data = data || {}

      // const direction = data.full ? 'desc' : 'asc'

      // const config = require('~/assets/js/config.mjs')
      const workshops = []
      // const Config = config.default

      // // eslint-disable-next-line no-console
      // console.debug(
      //   'Check variables',
      //   'Config:',
      //   Config,
      //   'config:',
      //   config,
      // )

      // ----- PRODUCTION

      // --- remove connexion to AIRTABLE
      // const Airtable = require('airtable')
      // Airtable.configure({
      //   endpointUrl: 'https://api.airtable.com',
      //   apiKey: Config.AIRTABLE_API_KEY,
      // })

      // const base = Airtable.base(Config.AIRTABLE_BASE.SWING.ID)
      // const eventsTable = base(Config.AIRTABLE_BASE.SWING.TABLES.EVENTS)

      // const records = await eventsTable
      //   .select({ sort: [{ field: 'Date début', direction }] })
      //   .all()

      // --- replace by plain file

      const records = await import('~/content/evenements/audepom.json').then(
        (m) => m.records || m
      )

      // // eslint-disable-next-line no-console
      // console.debug('Data', workshops, records)

      records.forEach((record) => {
        // // eslint-disable-next-line no-console
        // console.debug('Record', record)

        const fields = record.fields

        workshops.push({
          id: record.id,
          title: fields.Nom,
          year: Number(fields['Année']),
          location: fields.Lieu,
          organizer: fields.Organisateurs,
          danses: fields.Danses,
          startDate: fields['Date début'],
          endDate: fields['Date fin'],
          serie: fields.Serie,
          linkFacebook: fields['Event Facebook'],
          linkWebsite: fields['Site Web'],
          video: fields['Vidéo'],
        })
      })

      commit('set', workshops)
    }

    // // // eslint-disable-next-line no-console
    // // console.debug('get', data)
    //
    // if (data && data.full) {
    //   this.dispatch('workshops/sort', { direction: 'desc' })
    // } else {
    //   this.dispatch('workshops/sort', { direction: 'asc' })
    // }
  },
  // ,
  // sort({ commit, state, dispatch }, data) {
  //   const workshops = state.list
  //
  //   data = data || {
  //     direction: 'asc'
  //   }
  //
  //   orderBy(workshops, 'startData', data.direction)
  //
  //   commit('set', workshops)
  // }
}
