export default {
  APS_EMAIL: 'mecanikaswing@gmail.com',
  APS_EMAIL_PROTECTED: 'mecanikaswing [ @ ] gmail.com',
  APS_PHONE: '06 95 51 75 52',
  LOCALE: 'default',
  AIRTABLE_API_KEY: 'keyR4i9w58V6arsCy',
  // AIRTABLE_DATE_FORMAT: 'YYYY-MM-DD',
  // AIRTABLE_DATETIME_FORMAT: 'YYYY-MM-DD hh:ss',
  AIRTABLE_BASE: {
    SWING: {
      ID: 'appHzkhA3yVdKtAch',
      TABLES: {
        EVENTS: 'Evenements',
      },
    },
  },
  NEXT_MECANIKA_REGISTRATION_DATE: {
    OPEN: new Date('2023-02-19T18:00:00'),
    CLOSE: new Date('2023-03-16T23:59:00'),
  },
  REGISTRATION_LINK:
    'https://docs.google.com/forms/d/e/1FAIpQLSdeQo-w0GHNdfEgY9bA3WtCuOwwPHn1QAT2Rp8F8uW-u7HFXQ/viewform?usp=sf_link',
  // REGISTRATION_LINK_ATELIER_THEMATIQUE: 'https://forms.gle/4nNpRhArboHnKTBy6',
}
