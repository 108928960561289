export const state = () => ({
  page: null,
  section: null,
})

export const getters = {
  getPage(state) {
    return state.page
  },
  getSection(state) {
    return state.section
  },
}

export const mutations = {
  /**
   * set nav page
   */
  setPage(state, page) {
    state.page = page
  },
  /**
   * set nav section
   */
  setSection(state, section) {
    state.section = section
  },
}

// export const getters = {}
